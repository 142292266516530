<template>
  <div class="tabberWarp">
    <div class="warp">
        <!-- @change="getVal" -->
      <Item v-for="item in tabbarDes" :txt="item.txt" :key="item.id" :page="item.page"  :sel="selected">
        <img  :style="imgSizeFit(item.id)" :src="item.normalImg" slot="normalImg" />
        <img  :style="imgSizeFit(item.id)" :src="item.activeImg" slot="activeImg" />
      </Item>
    </div>
  </div>
</template>

<script>
import Item from "./item";
import { getToken } from "@/utils/auth";
import { getInfo } from "../../api/ThisInfo.js";
export default {
  components: {
    Item,
  },
  props: {
    selected: {
      type: String,
    },
  },
  data() {
    return {
      tabbarDes: [

      ],
    };
  },
  created() {
    // 获取权限
    if (getToken()) {
      getInfo().then((res) => {
        // 从新获取权限判断入口权限存放到VueX
        if (res.data.permission_info[0] != 0) {
          if (this.tabbarDes.length == 4) {
            return
          } else {
            this.tabbarDes = [
              {
                id:'1',
                txt: "首页",
                page: "",
                normalImg: require("../../assets/icon/home.png"),
                activeImg: require("../../assets/icon/ChangeHome.png"),
              },
              {
                id:'2',
                txt: "比赛",
                page: "CompetiH5",
                normalImg: require("../../assets/icon/Competi.png"),
                activeImg: require("../../assets/icon/ChangeCompeti.png"),
              },
              {
                id:'3',
                txt: "管理",
                page: "ManagementCenterH5",
                normalImg: require("../../assets/icon/ManagementCenter.png"),
                activeImg: require("../../assets/icon/ChangeManagementCenter.png"),
              },
              {
                id:'4',
                txt: "我的",
                page: "PersonalInfoH5",
                normalImg: require("../../assets/icon/PersonalInfo.png"),
                activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
              },
            ];
          }
        } else {
          if (this.tabbarDes.length == 3) {
            return
          } else {
            this.tabbarDes = [
              {
                id:'1',
                txt: "首页",
                page: "",
                normalImg: require("../../assets/icon/home.png"),
                activeImg: require("../../assets/icon/ChangeHome.png"),
              },
              {
                id:'2',
                txt: "比赛",
                page: "CompetiH5",
                normalImg: require("../../assets/icon/Competi.png"),
                activeImg: require("../../assets/icon/ChangeCompeti.png"),
              },
              {
                id:'4',
                txt: "我的",
                page: "PersonalInfoH5",
                normalImg: require("../../assets/icon/PersonalInfo.png"),
                activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
              },
            ];
          }
        }
      });
    } else {
      this.tabbarDes = [
        {
          id:'1',
          txt: "首页",
          page: "",
          normalImg: require("../../assets/icon/home.png"),
          activeImg: require("../../assets/icon/ChangeHome.png"),
        },
        {
          id:'2',
          txt: "比赛",
          page: "CompetiH5",
          normalImg: require("../../assets/icon/Competi.png"),
          activeImg: require("../../assets/icon/ChangeCompeti.png"),
        },
        {
          id:'4',
          txt: "我的",
          page: "PersonalInfoH5",
          normalImg: require("../../assets/icon/PersonalInfo.png"),
          activeImg: require("../../assets/icon/ChangePersonalInfo.png"),
        },
      ];
    }
  },
  methods: {
    imgSizeFit(id){
      if(id == 2){
        return {
          width: '22px',height: '22px'
        }
      }else if(id == 3 || id == 4){
        return {
          width: '14px',height: '14px'
        }
      }else{
        return {
          width: '20px',height: '20px'
        }
      }
    }
    // getVal:function(res){
    // 	this.selected = res;
    // }
  },
};
</script>

<style lang="scss" scoped>
.tabberWarp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 54px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-evenly;
  background: #fff;

  .warp {
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0;
  }

  .warp img {
    width: 20px;
    height: 20px;
  }
}
</style>